.aboutMe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  padding: 2rem;
  position: relative;
  z-index: 3;
  max-width: 100%;
  margin: 0 auto 2rem auto; /* Reduced from 4rem to 2rem */
}

.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.avatar {
  width: 22vw;
  max-width: 250px;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  border: none;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
}

.avatar:hover {
  transform: scale(1.02);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.spaceStyleHeading {
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
  letter-spacing: 0.1vw;
  font-weight: 700;
  color: #000000;
  margin-bottom: 0.5rem;
  position: relative;
  padding-bottom: 0;
  text-align: center;
}

.spaceStyleHeading::after {
  display: none;
}

.spaceStyleSubheading {
  font-family: 'Poppins', sans-serif;
  font-size: 1.3rem;
  letter-spacing: 0.05vw;
  font-weight: 400;
  color: #555555;
  margin-bottom: 0.7rem;
  text-align: center;
}

.spaceStyleText {
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.03vw;
  font-weight: 300;
  color: #333333;
  text-align: center;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.spaceStyleText i {
  font-style: italic;
  font-weight: 500;
  color: #000000;
}

/* Subtle emphasis for important text without strong bolding */
.subtle-emphasis {
  font-weight: 500;
  color: #1a1a1a;
  letter-spacing: 0.02vw;
}

.floating-social-icons {
  display: flex;
  gap: 1.5rem;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.social-icon {
  width: 28px;
  height: 28px;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.social-icon:hover {
  opacity: 1;
  transform: translateY(-3px);
}

/* Mobile adjustments for social icons */
@media (max-width: 768px) {
  .floating-social-icons {
    bottom: 10px;
    left: 10px;
    gap: 1rem;
    padding: 8px;
  }
  
  .social-icon {
    width: 24px;
    height: 24px;
  }
}

/* Featured section */
.featured-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  width: 100%;
  margin-top: 0; /* Reduced from 2rem to 0 */
  position: relative;
}

.featured-title-header {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  letter-spacing: 2px;
  color: #333;
}

.featured-items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.featured-items-container > a {
  display: block;
  width: 400px; /* Increased from 300px */
  height: 300px; /* Increased from 220px */
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.featured-item {
  position: relative;
  width: 400px; /* Increased from 300px */
  height: 300px; /* Increased from 220px */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.featured-item:hover,
.featured-items-container > a:hover .featured-item {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
}

.featured-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.featured-item:hover .featured-image,
.featured-items-container > a:hover .featured-image {
  transform: scale(1.05);
}

.featured-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  opacity: 0;
  transition: opacity 0.4s ease;
  text-align: center;
  box-sizing: border-box;
}

.featured-item:hover .featured-overlay,
.featured-items-container > a:hover .featured-overlay {
  opacity: 1;
}

.featured-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
  text-align: center;
  color: white;
}

.featured-description {
  font-family: 'Poppins', sans-serif;
  font-size: 0.95rem;
  text-align: center;
  line-height: 1.5;
  color: white;
}

/* Media queries for responsive adjustments */
@media screen and (max-width: 768px) {
  .aboutMe {
    padding: 1rem;
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 1200px) {
  .avatar {
    width: 180px;
    height: 180px;
  }
  
  .spaceStyleHeading {
    font-size: 2.5rem;
  }
  
  .featured-section {
    position: relative;
    bottom: auto;
    margin-top: 4rem;
  }
}

@media (max-width: 768px) {
  .avatar {
    width: 150px;
    height: 150px;
  }
  
  .spaceStyleHeading {
    font-size: 2rem;
  }
  
  .spaceStyleSubheading {
    font-size: 1rem;
  }
  
  .spaceStyleText {
    font-size: 0.9rem;
  }
  
  .featured-item {
    width: 280px; /* Increased from 150px */
    height: 200px; /* Increased from 150px */
  }
  
  .featured-items-container > a {
    width: 280px;
    height: 200px;
  }
  
  .featured-title {
    font-size: 1rem;
  }
  
  .featured-description {
    font-size: 0.7rem;
  }
}

@media (max-width: 480px) {
  .avatar {
    width: 120px;
    height: 120px;
  }
  
  .spaceStyleHeading {
    font-size: 1.8rem;
  }
  
  .spaceStyleSubheading {
    font-size: 0.9rem;
  }
  
  .spaceStyleText {
    font-size: 0.8rem;
  }
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 5rem; /* Add extra padding for the navbar */
}

/* Media query updates */
@media screen and (max-width: 768px) {
  .profile-container {
    padding: 1rem;
  }
}

.navigation-bar {
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 2rem;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  display: block !important;
}

.nav-links {
  display: flex !important;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 2rem;
}

.nav-link {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
  text-decoration: none;
  padding: 0.5rem 0;
  position: relative;
  transition: color 0.3s ease;
  display: block !important;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transition: width 0.3s ease;
  display: block !important;
}

.nav-link:hover {
  color: #000;
}

.nav-link:hover::after {
  width: 100%;
}

/* Mobile navigation */
@media (max-width: 768px) {
  .nav-links {
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .nav-link {
    font-size: 0.8rem;
    padding: 0.3rem 0;
  }
}

.experience-section,
.section-title,
.timeline,
.timeline::before,
.timeline-item,
.timeline-item::before,
.timeline-content,
.timeline-title,
.timeline-period,
.timeline-description,
.blog-section,
.blog-grid,
.blog-card,
.blog-image-container,
.blog-image,
.blog-content,
.blog-title,
.blog-date,
.blog-excerpt,
.blog-link,
.blog-link::after,
.contact-section,
.contact-container,
.contact-form,
.form-group,
.form-group label,
.form-group input,
.form-group textarea,
.submit-button,
.site-footer,
.copyright {
  display: none !important;
}

.placeholder-section {
  width: 100%;
  max-width: 800px;
  margin: 5rem auto;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.section-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #333;
  letter-spacing: 2px;
}

.coming-soon {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  color: #666;
}

@media (max-width: 768px) {
  .placeholder-section {
    width: 90%;
    padding: 1.5rem;
    margin: 3rem auto;
  }
  
  .section-title {
    font-size: 1.8rem;
  }
}

/* Add these styles to fix the Life Updates section */
.life-updates-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.image-buttons-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.image-button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Override SocialLinks.css styles for the life updates section */
.image-button-wrapper .iconStyle {
  width: 100px;
  height: 100px;
  border-radius: 0; /* Make images square */
  overflow: hidden; 
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-button-wrapper .iconStyle:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0,0,0,0.2);
}

.image-button-wrapper .imageStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details-panel {
  width: 100%;
  min-height: 150px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  padding: 1.5rem;
  margin-top: 1rem;
  text-align: center;
}

.details-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

.details-description {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.details-placeholder {
  font-style: italic;
  color: #888;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
  .image-buttons-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }

  .image-button-wrapper {
    width: auto;
  }

  .image-button-wrapper .iconStyle {
    width: 120px;
    height: 120px;
  }
}

/* Life Gallery Styles */
.life-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

.gallery-item {
  flex: 0 0 auto;
  width: 250px;
  height: 250px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.gallery-item:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.gallery-item:hover .gallery-image {
  transform: scale(1.05);
}

/* Media queries for responsive gallery */
@media (max-width: 768px) {
  .life-gallery {
    padding: 15px 0;
    gap: 15px;
  }
  
  .gallery-item {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 480px) {
  .gallery-item {
    width: 150px;
    height: 150px;
  }
}

/* Social Media Section Styles */
.social-media-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  width: 100%;
}

.social-profile-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  transition: transform 0.3s ease, color 0.3s ease;
  padding: 10px;
  border-radius: 8px;
  width: 80px;
}

.social-profile-link:hover {
  transform: translateY(-5px);
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
}

.social-profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-profile-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.social-platform-name {
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 480px) {
  .social-media-section {
    gap: 10px;
  }
  
  .social-profile-link {
    width: 70px;
    padding: 8px;
  }
  
  .social-profile-icon {
    width: 35px;
    height: 35px;
  }
  
  .social-platform-name {
    font-size: 0.7rem;
  }
}

/* Social Buttons Container */
.social-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  flex-wrap: wrap;
  position: relative;
  z-index: 5;
}

.social-button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: white;
  border: none;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.social-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.social-button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.2s ease;
}

.social-button:hover img {
  transform: scale(1.1);
}

@media (max-width: 480px) {
  .social-buttons-container {
    gap: 10px;
  }
  
  .social-button {
    width: 35px;
    height: 35px;
    padding: 6px;
  }
}
