.experience-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
  overflow-x: hidden;
  padding-top: 5rem;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 20;
  margin-bottom: 3rem;
}

.hire-me-header {
  text-align: center;
  font-size: 3.8rem;
  font-weight: 700;
  margin: 0;
  padding: 1.5rem 3rem;
  color: #000000;
  font-family: 'Montserrat', Times, serif;
}

.resume-container {
  background-color: #ffffff;
  margin: 0 auto;
  padding: 2.5rem 2rem;
  border-radius: 8px;
  max-width: 1300px;
  width: 90%;
  color: #000000;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.05rem;
}

.resume-header {
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 2px solid #000000;
  padding-bottom: 1.5rem;
}

.resume-name {
  font-size: 3.5rem;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 1rem;
  color: #000000;
  text-shadow: none;
  font-family: 'Times New Roman', Times, serif;
}

.resume-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1rem;
  color: #333333;
}

.resume-contact a {
  color: #333333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.resume-contact a:hover {
  color: #000000;
  text-decoration: underline;
}

.resume-section {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
}

.resume-section:last-child {
  border-bottom: none;
}

.resume-section h2 {
  color: #000000;
  margin-bottom: 1rem;
  font-size: 2.2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  padding-left: 12px;
  border-left: 4px solid #000000;
  font-family: 'Times New Roman', Times, serif;
}

.resume-section h2::before {
  content: none;
}

.resume-item {
  margin-bottom: 1.5rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #f0f0f0;
}

.resume-item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.resume-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.resume-item-header h3 {
  color: #000000;
  font-size: 1.5rem;
  margin: 0;
  font-weight: 600;
  font-family: 'Times New Roman', Times, serif;
}

.resume-company {
  display: block;
  color: #555555;
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.resume-location {
  color: #555555;
  font-style: italic;
  font-size: 1.15rem;
}

.resume-item-subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;
}

.resume-date {
  color: #555555;
  font-style: italic;
  font-size: 1.15rem;
}

.resume-item p {
  margin: 0.5rem 0;
  line-height: 1.6;
  color: #333333;
  font-size: 1.05rem;
}

.resume-item-details {
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

.resume-item-details li {
  margin-bottom: 0.8rem;
  line-height: 1.6;
  position: relative;
  color: #333333;
  font-size: 1.25rem;
}

.resume-item-details li::before {
  content: "•";
  position: absolute;
  left: -1rem;
  color: #000000;
  font-size: 1.4rem;
}

.resume-item-details li strong {
  font-weight: 700;
}

.number-bold {
  font-weight: 700;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.skill-item h3 {
  color: #000000;
  margin-bottom: 0.5rem;
  font-size: 1.35rem;
}

.skill-item p {
  line-height: 1.6;
  color: #333333;
  font-size: 1.05rem;
}

.resume-download-link {
  text-align: center;
  margin-top: 2rem;
}

.download-button {
  display: inline-block;
  background-color: #000000;
  color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.5px;
  font-size: 1.1rem;
}

.download-button:hover {
  background-color: #333333;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.social-icons-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.social-icons {
  display: flex;
  gap: 1.5rem;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.social-icon {
  width: 35px;
  height: 35px;
  filter: brightness(0.2);
  transition: all 0.3s ease;
  opacity: 0.85;
}

.social-icon:hover {
  transform: scale(1.2);
  filter: brightness(0);
  opacity: 1;
}

@media (max-width: 768px) {
  .resume-container {
    margin: 1rem;
    padding: 1.5rem;
  }
  
  .resume-name {
    font-size: 2.2rem;
  }
  
  .resume-contact {
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
  }
  
  .resume-contact span:nth-child(even) {
    display: none;
  }
  
  .resume-item-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .resume-location {
    margin-top: 0.3rem;
  }
  
  .resume-item-subheader {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .resume-date {
    margin-top: 0.3rem;
  }
  
  .skills-grid {
    grid-template-columns: 1fr;
  }
  
  .social-icons {
    gap: 1rem;
    padding: 0.8rem;
  }
  
  .social-icon {
    width: 30px;
    height: 30px;
  }
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 85vh;
  overflow: hidden;
  padding-top: 0;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  background-color: #f5f5f5;
}

.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  flex: 1;
}

.pdf-viewer {
  width: 100%;
  height: 85vh;
  border: none;
}

.pdf-document {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-page {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin: 10px;
}

.pdf-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.pdf-control-button {
  padding: 8px 16px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.pdf-control-button.zoom {
  padding: 8px 12px;
  font-weight: bold;
}

.pdf-control-button:hover:not(:disabled) {
  background-color: #000;
  transform: translateY(-2px);
}

.pdf-control-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.page-info, .zoom-info {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: #555;
}

/* Download button styles */
.pdf-download-link {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/* PDF loading animation */
.react-pdf__Document.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.react-pdf__Document.loading::after {
  content: "Loading resume...";
  font-family: 'Poppins', sans-serif;
  color: #555;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.pdf-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 4px;
  text-align: center;
}

.pdf-error p {
  margin: 10px 0;
  font-family: 'Poppins', sans-serif;
  color: #555;
}

.pdf-error p:first-child {
  color: #d63031;
  font-weight: 500;
}

/* Hide the old floating social icons style */
.floating-social-icons {
  display: none !important;
}

body {
  font-family: 'Times New Roman', Times, serif;
  color: #333333;
  background-color: #f9f9f9;
} 