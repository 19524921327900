/* Reset all previous styles */
.contact-page * {
  /* This ensures our inline styles take precedence */
  box-sizing: border-box !important;
}

/* Reset any background positioning that might be interfering */
#container-stars, #glow, #stars, .circle, .network-lines, canvas {
  z-index: -999 !important;
  position: fixed !important;
  pointer-events: none !important;
}

.contact-page {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;
}

.network-lines, 
#stars,
#container-stars,
#glow {
  z-index: -1 !important;
  position: absolute !important;
}

.page-header {
  text-align: center;
  padding-top: 5rem;
  margin-bottom: 3rem;
  position: relative;
}

.page-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
  font-weight: 800;
  letter-spacing: 3px;
  color: #222;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}

.header-underline {
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #333 0%, #666 100%);
  margin: 0 auto;
}

.contact-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  position: relative;
}

/* Contact info section */
.contact-info {
  flex: 1;
  padding: 2rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.contact-info h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #333;
}

.contact-info p {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 2rem;
}

.contact-details {
  margin-bottom: 2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
}

.contact-icon {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.email-icon {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>');
}

.location-icon {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>');
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f5f5f5;
  transition: all 0.3s ease;
}

.social-link:hover {
  transform: translateY(-5px);
  background-color: #e9e9e9;
}

.social-link img {
  width: 24px;
  height: 24px;
}

/* Form section */
.contact-form-container {
  flex: 2;
  padding: 2.5rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #333;
}

.form-group input,
.form-group textarea {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #999;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
  outline: none;
}

/* Validation errors */
p[role="alert"] {
  color: #e74c3c;
  font-size: 0.85rem;
  margin-top: 0.25rem;
  font-family: 'Poppins', sans-serif;
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  20%, 60% { transform: translateX(-5px); }
  40%, 80% { transform: translateX(5px); }
}

.submit-button {
  padding: 1rem 2rem;
  background: linear-gradient(135deg, #333 0%, #555 100%);
  color: white;
  border: none;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
  align-self: flex-start;
}

.submit-button:hover {
  background: linear-gradient(135deg, #222 0%, #444 100%);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.status-message {
  padding: 1rem;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  margin-top: 1rem;
  animation: fadeIn 0.5s ease;
}

.status-message.success {
  background-color: rgba(46, 204, 113, 0.1);
  border: 1px solid rgba(46, 204, 113, 0.5);
  color: #27ae60;
}

.status-message.error {
  background-color: rgba(231, 76, 60, 0.1);
  border: 1px solid rgba(231, 76, 60, 0.5);
  color: #e74c3c;
}

/* Success message styling */
.success-message {
  padding: 2rem;
  background-color: rgba(46, 204, 113, 0.1);
  border: 1px solid rgba(46, 204, 113, 0.3);
  border-radius: 8px;
  text-align: center;
  animation: fadeIn 0.5s ease;
}

.success-message h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  color: #27ae60;
  margin-bottom: 1rem;
}

.success-message p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes growWidth {
  from {
    width: 0;
  }
  to {
    width: 100px;
  }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .page-title {
    font-size: 3rem;
  }
  
  .contact-container {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .page-title {
    font-size: 2.5rem;
  }
  
  .contact-container {
    flex-direction: column;
    gap: 2rem;
  }
  
  .contact-info,
  .contact-form-container {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .page-title {
    font-size: 2rem;
  }
  
  .contact-container {
    padding: 1rem;
  }
  
  .contact-info h2 {
    font-size: 1.5rem;
  }
  
  .submit-button {
    width: 100%;
  }
} 