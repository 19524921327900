.floating-social-icons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
}

.floating-social-icons .iconStyle {
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0.5rem;
  border-radius: 50%;
  z-index: 10000;
}

.floating-social-icons .iconStyle:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.floating-social-icons .imageStyle {
  width: 40px;
  height: 40px;
  filter: grayscale(100%);
  opacity: 0.75;
  transition: all 0.3s ease;
  border-radius: 50%;
}

.floating-social-icons .iconStyle:hover .imageStyle {
  filter: grayscale(0%);
  opacity: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
} 