body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  z-index: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

/* Make sure the z-index for canvas/background elements is low */
canvas, #stars, #glow, .circle {
  z-index: -1 !important;
  position: fixed !important;
}

/* Make sure content is above background */
.App, 
.page-header, 
.contact-container,
.contact-page,
.contact-form,
button,
input,
textarea,
label,
h1, h2, h3, h4, h5, h6,
p, a {
  position: relative;
  z-index: 10;
}
